import { Component, Prop, Emit, Watch,Vue } from "vue-property-decorator";

export class Model {}

@Component
export default class extends Vue {
  @Prop()
  public value: any;
  @Emit("onChange")
  public onChange() {
    //
  }
  @Watch("value.value")
  public change() {
    this.value.dummy = [" ", " ", " ", " ", " ", " ", " "];
    if(this.value.value.length ===0){
      return;
    }
    const charas = ("       " + Number(this.value.value).toLocaleString())
      .slice(-7)
      .split("");
    for (let i = 0; i <= charas.length - 1; i++) {
      this.value.dummy[i] = charas[i];
    }
  }
  @Emit("onInput")
  public onInput() {
    //
  }
  public focusIn(index: number) {
    this.value.focus = true;
  }
  public focusOut() {
    this.value.focus = false;
  }
}
