var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inputPrice", class: { focus: _vm.value.priceFocus } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value.value,
            expression: "value.value",
          },
        ],
        attrs: {
          type: "text",
          id: "inputPrice",
          maxlength: _vm.value.maxLength,
        },
        domProps: { value: _vm.value.value },
        on: {
          focus: _vm.focusIn,
          blur: _vm.focusOut,
          change: _vm.onChange,
          input: [
            function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.value, "value", $event.target.value)
            },
            _vm.onInput,
          ],
        },
      }),
      _c("div", { staticClass: "dummy" }, [
        _c("div", [_vm._v(" " + _vm._s(_vm.value.dummy[0]) + " ")]),
        _c("div", [_vm._v(" " + _vm._s(_vm.value.dummy[1]) + " ")]),
        _c("div", [_vm._v(" " + _vm._s(_vm.value.dummy[2]) + " ")]),
        _c("div", { staticClass: "comma" }, [
          _vm._v(" " + _vm._s(_vm.value.dummy[3]) + " "),
        ]),
        _c("div", [_vm._v(" " + _vm._s(_vm.value.dummy[4]) + " ")]),
        _c("div", [_vm._v(" " + _vm._s(_vm.value.dummy[5]) + " ")]),
        _c("div", [_vm._v(" " + _vm._s(_vm.value.dummy[6]) + " ")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }